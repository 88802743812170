import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Playground from 'components/Playground';
import Spinner from "components/Spinner";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "spinner"
    }}>{`Spinner`}</h1>

    <Playground __position={0} __code={'<Spinner color=\"primary\" />\n<Spinner color=\"secondary\" />\n<Spinner color=\"success\" />\n<Spinner color=\"danger\" />\n<Spinner color=\"warning\" />\n<Spinner color=\"info\" />\n<Spinner color=\"light\" />\n<Spinner color=\"dark\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Spinner,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Spinner color="primary" mdxType="Spinner" />
  <Spinner color="secondary" mdxType="Spinner" />
  <Spinner color="success" mdxType="Spinner" />
  <Spinner color="danger" mdxType="Spinner" />
  <Spinner color="warning" mdxType="Spinner" />
  <Spinner color="info" mdxType="Spinner" />
  <Spinner color="light" mdxType="Spinner" />
  <Spinner color="dark" mdxType="Spinner" />
    </Playground>
    <Playground __position={1} __code={'<Spinner type=\"grow\" color=\"primary\" />\n<Spinner type=\"grow\" color=\"secondary\" />\n<Spinner type=\"grow\" color=\"success\" />\n<Spinner type=\"grow\" color=\"danger\" />\n<Spinner type=\"grow\" color=\"warning\" />\n<Spinner type=\"grow\" color=\"info\" />\n<Spinner type=\"grow\" color=\"light\" />\n<Spinner type=\"grow\" color=\"dark\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Spinner,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Spinner type="grow" color="primary" mdxType="Spinner" />
  <Spinner type="grow" color="secondary" mdxType="Spinner" />
  <Spinner type="grow" color="success" mdxType="Spinner" />
  <Spinner type="grow" color="danger" mdxType="Spinner" />
  <Spinner type="grow" color="warning" mdxType="Spinner" />
  <Spinner type="grow" color="info" mdxType="Spinner" />
  <Spinner type="grow" color="light" mdxType="Spinner" />
  <Spinner type="grow" color="dark" mdxType="Spinner" />
    </Playground>
    <Playground __position={2} __code={'<Spinner size=\"sm\" color=\"primary\" /> <Spinner size=\"sm\" color=\"secondary\" />{\' \'}\n<Spinner style={{ width: \'3rem\', height: \'3rem\' }} />{\' \'}\n<Spinner style={{ width: \'3rem\', height: \'3rem\' }} type=\"grow\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Spinner,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Spinner size="sm" color="primary" mdxType="Spinner" />{' '}
  <Spinner size="sm" color="secondary" mdxType="Spinner" />{' '}
  <Spinner style={{
        width: '3rem',
        height: '3rem'
      }} mdxType="Spinner" />{' '}
  <Spinner style={{
        width: '3rem',
        height: '3rem'
      }} type="grow" mdxType="Spinner" />
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <p><a href="https://reactstrap.github.io/components/spinners/" target="_blank">Reactstrap Spinner</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      